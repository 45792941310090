import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
// import { CardDropdown as textLang, monthsList } from 'staticData/languages';
import AppContext from 'context/Context';
import { Modal, Row, Col, Form, InputGroup, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { endpointInterface } from 'services/endpointInterface/endpointInterface';
import { currencyMap } from 'services/coins/common';
import BenchmarkChart from './BenchmarkChart';
import MonthlyBacktest from 'pages/common/MonthlyBacktest.js';
import { CardDropdown as textLang, monthsList } from 'staticData/languages';
import {
  defaultPortfolio,
  portfolioList
} from 'pages/CreateBot/staticData/portfolioList';
import { delistedCoins } from 'services/coins/common';
import { calcTotalTimeWeightedReturn } from 'services/chartsData/functions';
import Flex from 'components/common/Flex';
import { hideCapitalAlignement } from 'staticData/common';

const MultiBenchmark = ({
  showModal,
  setShowModal,
  bot,
  eventPending = false
}) => {
  const {
    config: { lang, currency }
  } = useContext(AppContext);

  const { coins } =
    portfolioList.find(
      portfolio => String(bot.type) === String(portfolio.id)
    ) || defaultPortfolio;
  const now = new Date();
  const [loaded, setLoaded] = useState(false);
  const [walletData, setWalletData] = useState([]);
  const [chartData, setChartData] = useState({});
  const [selectedYear, setSelectedYear] = useState(now.getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(now.getMonth());
  const [yearsMonthsStructure, setYearsMonthsStructure] = useState({});
  const [hasData, setHasData] = useState(true);
  const [lastUpdateTs, setLastUpdateTs] = useState();
  const [monthlyData, setMonthlyData] = useState([]);

  const getCurrentTimepoints = (chartStartingDate, data) => {
    const isAll = timepointsToCheck => {
      if (Object.keys(data[timepointsToCheck]).length > 0)
        return timepointsToCheck;
      else return 'all_timepoints';
    };

    const now = new Date();

    // Calculate the difference in milliseconds
    const diffMilliseconds = now - chartStartingDate;
    // Convert milliseconds to hours and days
    const diffHours = diffMilliseconds / (1000 * 60 * 60);
    const diffDays = diffMilliseconds / (1000 * 60 * 60 * 24);

    if (diffHours > 4 && diffDays <= 1) {
      return ['4h_timepoints', 144]; // once every 10 minutes
    } else if (diffDays <= 1) {
      return [isAll('1d_timepoints'), 48]; // once every 30 minutes
    } else if (diffDays <= 7) {
      return [isAll('7d_timepoints'), 24]; // once every hour
    } else if (diffDays <= 30) {
      return [isAll('30d_timepoints'), 6]; // once every 4 hours
    } else if (diffDays <= 180) {
      return [isAll('6m_timepoints'), 3]; // once every 8 hours
    } else {
      return ['all_timepoints', 1]; // once a day
    }
  };

  const checkHasNoData = (startingDate, createdAt) => {
    const startingDateObj = new Date(startingDate);
    const createdAtObj = new Date(createdAt);

    const startingYear = startingDateObj.getFullYear();
    const startingMonth = startingDateObj.getMonth();

    const createdYear = createdAtObj.getFullYear();
    const createdMonth = createdAtObj.getMonth();

    return (
      startingYear < createdYear ||
      (startingYear === createdYear && startingMonth < createdMonth)
    );
  };

  const polAndMaticCondition = (coin, startingDate) => {
    const maticEndDate = new Date('2024-08-31T00:00:00Z');
    const polStartDate = new Date('2024-09-30T00:00:00Z');
    const startDate = new Date(startingDate);

    if (
      (coin === 'MATIC' && startDate < maticEndDate) ||
      (coin === 'POL' && startDate > polStartDate) ||
      (coin !== 'MATIC' && coin !== 'POL')
    )
      return true;
    else return false;
  };

  const processData = (
    data,
    startingDate,
    snapshots = walletData['snapshots'] || []
  ) => {
    // Create an object for the processed data
    var processedData = {
      TIMESTAMP: [],
      HODLIE: { values: [], normalizedValues: [] },
      ADD_CAPITAL: { values: [], normalizedValues: [] }
    };
    const addCapitalValueChanges = {};
    // Check if the data is empty
    if (checkHasNoData(startingDate, data.bot_details.created_at))
      return processedData;

    // Helper function to get the initial timepoints based on startingDate
    const [initialTimepoints, pointsPerDay] = getCurrentTimepoints(
      startingDate,
      data
    );
    const rawData = { ...data[initialTimepoints] };

    // Create a mapping of timestamp in milliseconds to the original string
    const timestampMap = Object.keys(rawData).reduce((map, ts) => {
      map[new Date(ts).getTime()] = ts;
      return map;
    }, {});

    // Extract timestamps and convert them to integers
    const timestamps = Object.keys(timestampMap).map(ts => parseInt(ts));

    // Convert startingDate to milliseconds
    const timezoneOffset = new Date(startingDate).getTimezoneOffset();
    const startingTimestamp =
      new Date(startingDate).getTime() - timezoneOffset * 60 * 1000;
    const endOfMonth = new Date(
      new Date(startingDate).getFullYear(),
      new Date(startingDate).getMonth() + 1,
      1
    ).getTime();

    // Filter timestamps to include only those after the starting date
    const filteredTimestamps = timestamps.filter(
      ts =>
        ts >= startingTimestamp && ts <= endOfMonth - timezoneOffset * 60 * 1000
    );

    const currencyKey = currencyMap[currency];

    // Iterate over each timestamp to build the price series dynamically
    filteredTimestamps.forEach(timestamp => {
      const prices = rawData[timestampMap[timestamp]]?.prices;
      if (prices) {
        Object.keys(prices).forEach(coin => {
          if (
            !processedData[coin] &&
            coins.concat(delistedCoins).includes(coin) &&
            polAndMaticCondition(coin, startingDate)
          ) {
            processedData[coin] = {
              values: [],
              normalizedValues: []
            };
          }
        });
      }
    });

    // Sort the filtered timestamps
    const sortedTimestamps = [...filteredTimestamps].sort((a, b) => a - b);

    // If the first timestamp is greater than the starting date, generate missing timestamps
    const missingTimestamps = [];
    const msInDay = 24 * 60 * 60 * 1000;
    const interval = Math.floor(msInDay / pointsPerDay);
    if (
      sortedTimestamps.length > 0 &&
      sortedTimestamps[0] > startingTimestamp
    ) {
      let currentTimestamp = startingTimestamp;

      while (currentTimestamp < sortedTimestamps[0]) {
        missingTimestamps.push(currentTimestamp);
        currentTimestamp += interval;
      }
    }

    // Populate the processedData object in order
    sortedTimestamps.forEach((ts, index) => {
      const originalTimestamp = timestampMap[ts];
      processedData.TIMESTAMP.push(originalTimestamp);
      let hodlieValue = rawData[originalTimestamp][currencyKey];
      if (index === 0 && missingTimestamps.length)
        hodlieValue = data.bot_details.initial_eqv[currencyMap[currency]];
      processedData.HODLIE.values.push(hodlieValue);
      processedData.HODLIE.normalizedValues.push(hodlieValue);

      // Populate the ADD_CAPITAL array based on allocation changes
      let addCapitalValue = null;
      let valueChange = null;
      if (snapshots) {
        const allocationChanges = snapshots;
        for (let change of allocationChanges) {
          const closeTimestamp = new Date(change.ts).getTime();
          if (
            index > 0 &&
            closeTimestamp > sortedTimestamps[index - 1] &&
            closeTimestamp <= ts
          ) {
            addCapitalValue = processedData.HODLIE.values[index];
            valueChange = change.event_metadata.eqv[currencyMap[currency]] || 0;
            break;
          }
        }
      }
      processedData.ADD_CAPITAL.values.push(valueChange);
      processedData.ADD_CAPITAL.normalizedValues.push(addCapitalValue);
      if (valueChange) addCapitalValueChanges[index] = valueChange;

      Object.keys(processedData).forEach(coin => {
        if (
          coin !== 'TIMESTAMP' &&
          coin !== 'HODLIE' &&
          coin !== 'ADD_CAPITAL'
        ) {
          processedData[coin].values.push(
            rawData[originalTimestamp].prices
              ? rawData[originalTimestamp].prices[coin] || null
              : null
          );
        }
      });
    });

    // Get initial value of HODLIE and coin values
    const initialHodlieValue =
      processedData.HODLIE.values.length > 0
        ? processedData.HODLIE.values[0]
        : null;
    const initialCoinValues = {};

    Object.keys(processedData).forEach(coin => {
      if (coin !== 'TIMESTAMP' && coin !== 'HODLIE' && coin !== 'ADD_CAPITAL') {
        initialCoinValues[coin] = processedData[coin].values[0];
      }
    });

    const addCapitalValueChangesKeys = Object.keys(addCapitalValueChanges)
      .map(Number)
      .sort((a, b) => a - b);

    if (initialHodlieValue !== null) {
      // Normalize other arrays based on the initial value of HODLIE and initial coin values
      Object.keys(processedData).forEach(coin => {
        if (
          coin !== 'TIMESTAMP' &&
          coin !== 'HODLIE' &&
          coin !== 'ADD_CAPITAL'
        ) {
          const initialCoinValue = initialCoinValues[coin];
          processedData[coin].normalizedValues = processedData[coin].values.map(
            (value, index) => {
              if (value !== null) {
                let addedCapital = 0;
                addCapitalValueChangesKeys.forEach(key => {
                  if (key <= index) {
                    addedCapital += addCapitalValueChanges[key];
                  }
                });
                const correctionFactor = value / initialCoinValue;
                return initialHodlieValue * correctionFactor + addedCapital;
              }
              return null;
            }
          );
        }
      });
    }

    // Also update the TIMESTAMP in processedData with the missing timestamps HEAD
    if (missingTimestamps.length) {
      processedData.TIMESTAMP.unshift(
        ...missingTimestamps.map(ts =>
          new Date(ts - timezoneOffset * 60 * 1000).toISOString()
        )
      );
    }

    // If the final timestamp is less than the last day of the month, generate the missing timestamps
    const lastTimestamp =
      sortedTimestamps[sortedTimestamps.length - 1] || startingTimestamp;
    let currentTimestampEnd = lastTimestamp + interval;

    // Create the missing timestamps at the end
    const additionalMissingTimestamps = [];
    while (currentTimestampEnd <= endOfMonth) {
      additionalMissingTimestamps.push(currentTimestampEnd);
      currentTimestampEnd += interval;
    }

    // Add the missing timestamps to processedData QUEUE
    processedData.TIMESTAMP.push(
      ...additionalMissingTimestamps.map(ts =>
        new Date(ts - timezoneOffset * 60 * 1000).toISOString()
      )
    );

    // Calculate the number of missing data
    const missingCount = missingTimestamps.length;
    // Create an array of nulls to be added
    const nullArray = Array(missingCount).fill(null);

    // Add nulls to the beginning of all arrays in processedData
    Object.keys(processedData).forEach(key => {
      if (key !== 'TIMESTAMP') {
        if (processedData[key].values) {
          processedData[key].values.unshift(...nullArray);
        }
        if (processedData[key].normalizedValues) {
          processedData[key].normalizedValues.unshift(...nullArray);
        }
      }
    });

    // Calculate the number of additional missing data points
    const additionalMissingCount = additionalMissingTimestamps.length;
    // Create an array of nulls to be added
    const additionalNullArray = Array(additionalMissingCount).fill(null);

    // Add the nulls to all other arrays in processedData
    Object.keys(processedData).forEach(key => {
      if (key !== 'TIMESTAMP') {
        if (processedData[key].values) {
          processedData[key].values.push(...additionalNullArray);
        }
        if (processedData[key].normalizedValues) {
          processedData[key].normalizedValues.push(...additionalNullArray);
        }
      }
    });

    processedData.TIMESTAMP[processedData.TIMESTAMP.length - 1] = new Date(
      endOfMonth - 1
    ).toISOString();

    return processedData;
  };

  function findSurroundingIndices(data, objArray, tsSpace) {
    return objArray
      .map(obj => {
        let useTSspace = 'all_timepoints';
        const eventTimestamp = new Date(obj.ts).getTime();
        const thisMonth = new Date().getMonth();
        const thisYear = new Date().getFullYear();
        if (
          new Date(obj.ts).getMonth() === thisMonth &&
          new Date(obj.ts).getFullYear() === thisYear
        )
          useTSspace = tsSpace;
        let timestamps = Object.keys(data[useTSspace]);

        let beforeIndex = -1;
        let afterIndex = -1;

        for (let i = 0; i < timestamps.length; i++) {
          const currentTimestamp = new Date(timestamps[i]).getTime();

          if (currentTimestamp <= eventTimestamp) {
            beforeIndex = i;
          } else if (currentTimestamp > eventTimestamp && afterIndex === -1) {
            afterIndex = i;
            break;
          }
        }

        // Return null if either index is not found
        if (beforeIndex === -1 || afterIndex === -1) {
          return null;
        }

        return {
          preValue:
            data[useTSspace][timestamps[beforeIndex]][currencyMap[currency]],
          afterValue:
            data[useTSspace][timestamps[afterIndex]][currencyMap[currency]],
          ts: obj.ts
        };
      })
      .filter(item => item !== null); // Optionally, filter out null results
  }

  function filterItemsFromPreviousMonth(items, referenceDate, useCurrentMonth) {
    // Ottieni il mese e l'anno precedenti rispetto alla data di riferimento
    const prevMonth =
      referenceDate.getMonth() === 0 ? 11 : referenceDate.getMonth() - 1;
    const thisMonth = referenceDate.getMonth();

    const referenceYear = useCurrentMonth
      ? referenceDate.getFullYear()
      : referenceDate.getMonth() === 0
      ? referenceDate.getFullYear() - 1
      : referenceDate.getFullYear();

    return items.filter(item => {
      const itemDate = new Date(item.ts);
      // Verifica se l'oggetto appartiene al mese precedente e che il timestamp fino a referenceDate
      const referenceMonth = useCurrentMonth ? thisMonth : prevMonth;
      const isInReferenceMonth =
        itemDate.getMonth() === referenceMonth &&
        itemDate.getFullYear() === referenceYear;

      return isInReferenceMonth && itemDate <= referenceDate;
    });
  }

  const getMonthlyPercentages = (
    allData,
    snapshots,
    initialWalletEqv,
    lastCurrencyValue,
    tsSpace
  ) => {
    const data = allData['all_timepoints'];
    const keys = Object.keys(data).sort(); // Sort the keys (timestamps)
    const monthlyChanges = [];

    const indices = findSurroundingIndices(allData, snapshots, tsSpace);

    let currentMonth = null;
    let firstCurrencyValue = null;
    let lastKeyOfMonth = null;

    keys.forEach((key, index) => {
      const date = new Date(key);
      const month = date.getUTCFullYear() + '-' + (date.getUTCMonth() + 1);

      if (month !== currentMonth) {
        // If we are switching to a new month and it's not the first one we're processing
        if (
          currentMonth !== null &&
          firstCurrencyValue !== null &&
          lastKeyOfMonth !== null
        ) {
          const filteredData = filterItemsFromPreviousMonth(
            indices,
            date,
            false
          );
          const lastCurrencyValue = data[lastKeyOfMonth][currencyMap[currency]];
          const perc = calcTotalTimeWeightedReturn(
            firstCurrencyValue,
            filteredData,
            lastCurrencyValue
          );
          monthlyChanges.push(perc.toFixed(2));
        }
        // Reset for the new month
        currentMonth = month;

        // Get the first currency value of the month and potentially adjust it
        firstCurrencyValue =
          index === 0 ? initialWalletEqv : data[key][currencyMap[currency]];
      }
      if (index < keys.length - 1) lastKeyOfMonth = keys[index + 1];
      else lastKeyOfMonth = key;
    });

    // Calculate the last month's change if it exists
    if (
      currentMonth !== null &&
      firstCurrencyValue !== null &&
      lastKeyOfMonth !== null
    ) {
      const lastTS = new Date(keys[keys.length - 1]);
      const filteredData = filterItemsFromPreviousMonth(indices, lastTS, true);
      const perc = calcTotalTimeWeightedReturn(
        firstCurrencyValue,
        filteredData,
        lastCurrencyValue
      );
      monthlyChanges.push(perc.toFixed(2));
    }
    setMonthlyData(monthlyChanges);
  };

  const getBotWalletStats = async botID => {
    var params = { bot_id: botID, prices: true };
    let walletStatsResponse = await endpointInterface(
      lang,
      'backend',
      'getWalletBot',
      'get',
      true,
      params,
      true
    );
    if (walletStatsResponse.validResponse) {
      let data = walletStatsResponse.data;
      hideCapitalAlignement(data, currencyMap[currency]);
      return data[botID];
    } else {
      toast.error(walletStatsResponse.responseMessage, { closeButton: false });
      setTimeout(() => {
        toast.dismiss();
      }, 5000);
      return {};
    }
  };

  function generateYearMonthStructure(startDate) {
    const start = new Date(startDate);
    const startYear = start.getFullYear();
    const startMonth = start.getMonth(); // Months range from 0 (January) to 11 (December)
    const now = new Date();
    const currentYear = now.getFullYear();
    const currentMonth = now.getMonth();
    const result = {};

    for (let year = startYear; year <= currentYear; year++) {
      if (year === startYear && year === currentYear) {
        // If the start year and current year are the same, list months from startMonth to currentMonth
        result[year] = Array.from(
          { length: currentMonth - startMonth + 1 },
          (_, i) => i + startMonth
        );
      } else if (year === startYear) {
        // For start year, list months from startMonth to December
        result[year] = Array.from(
          { length: 12 - startMonth },
          (_, i) => i + startMonth
        );
      } else if (year === currentYear) {
        // For current year, list months from January to currentMonth
        result[year] = Array.from({ length: currentMonth + 1 }, (_, i) => i);
      } else {
        // For full intermediate years, list months from January to December
        result[year] = Array.from({ length: 12 }, (_, i) => i);
      }
    }
    setYearsMonthsStructure(result);
  }

  const handleChangeDate = (year, month) => {
    var localMonth = month;
    if (year !== selectedYear) {
      setSelectedYear(year);
      if (now.getFullYear() === Number(year)) localMonth = 0;
      else localMonth = 11;
      setSelectedMonth(localMonth);
    }
    if (month !== selectedMonth) setSelectedMonth(month);
    const firstDay = new Date(year, localMonth, 1);
    const localProcessedData = processData(walletData, firstDay);
    setChartData(localProcessedData);
  };

  const getLastValueThisMonth = (localWalletStats, firstDayOfMonth) => {
    const rightTsSpace = getCurrentTimepoints(
      firstDayOfMonth,
      localWalletStats
    )[0];
    const walletKeys = Object.keys(localWalletStats[rightTsSpace]);
    const lastCurrencyValue =
      localWalletStats[rightTsSpace][walletKeys[walletKeys.length - 1]][
        currencyMap[currency]
      ];
    return { lastValueThisMonth: lastCurrencyValue, rightTsSpace };
  };

  const formatDate = (dateString, lang) => {
    const date = new Date(dateString);
    const months = monthsList.monthsLong[lang];

    const day = date.getDate();
    const month = months[date.getMonth()];
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${day} ${month} h${hours}:${minutes}`;
  };

  useEffect(async () => {
    if (showModal && !loaded && bot && Object.keys(bot).length > 0) {
      // get wallet stats
      const localWalletStats = await getBotWalletStats(bot.id);
      if (
        localWalletStats['all_timepoints'] &&
        Object.keys(localWalletStats['all_timepoints']).length > 0
      ) {
        setWalletData(localWalletStats);
        // get first day of month and current timepoints
        const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
        // process data for chart
        const localProcessedData = processData(
          localWalletStats,
          firstDayOfMonth,
          localWalletStats['snapshots']
        );
        generateYearMonthStructure(localWalletStats.bot_details.created_at);
        setChartData(localProcessedData);
        const { lastValueThisMonth, rightTsSpace } = getLastValueThisMonth(
          localWalletStats,
          firstDayOfMonth
        );
        const initialWalletEqv =
          localWalletStats.bot_details.initial_eqv[currencyMap[currency]];
        getMonthlyPercentages(
          localWalletStats,
          localWalletStats['snapshots'],
          initialWalletEqv,
          lastValueThisMonth,
          rightTsSpace
        );
        const localLastTS = Object.keys(localWalletStats[rightTsSpace])[
          Object.keys(localWalletStats[rightTsSpace]).length - 1
        ];
        setLastUpdateTs(formatDate(localLastTS, lang));
      } else setHasData(false);
      setLoaded(true);
    }
  }, [bot, showModal]);

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title id="contained-modal-title-vcenter">
          {textLang.bot_statistics[lang]}
        </Modal.Title>
      </Modal.Header>
      {loaded ? (
        hasData ? (
          eventPending ? (
            <Modal.Body
              as={Flex}
              className="justify-content-center align-items-center"
            >
              <h5>{textLang.eventPending[lang]}</h5>
            </Modal.Body>
          ) : (
            <Modal.Body>
              {yearsMonthsStructure &&
                Object.keys(yearsMonthsStructure).length > 0 && (
                  <div className="d-sm-flex align-items-center mb-3">
                    <h5 className="d-none d-sm-block">
                      {textLang.selectInterval[lang]}
                    </h5>
                    <Form as={Row} className="ps-sm-3 gx-2">
                      <Col xs="auto">
                        <InputGroup size="md">
                          <Form.Select
                            className="pe-5"
                            defaultValue={selectedYear}
                            onChange={({ target }) =>
                              handleChangeDate(target.value, selectedMonth)
                            }
                          >
                            {Object.keys(yearsMonthsStructure).map(year => (
                              <option key={year} value={year}>
                                {year}
                              </option>
                            ))}
                          </Form.Select>
                        </InputGroup>
                      </Col>
                      <Col xs="auto">
                        <InputGroup size="md">
                          <Form.Select
                            className="pe-5"
                            defaultValue={selectedMonth}
                            onChange={({ target }) =>
                              handleChangeDate(selectedYear, target.value)
                            }
                          >
                            {yearsMonthsStructure[selectedYear].map(month => (
                              <option key={month} value={month}>
                                {monthsList.monthsLong[lang][month]}
                              </option>
                            ))}
                          </Form.Select>
                        </InputGroup>
                      </Col>
                    </Form>
                  </div>
                )}
              <BenchmarkChart data={chartData} />
              <MonthlyBacktest
                mom={monthlyData}
                start_date={bot.created_at}
                simulation_periods={-1}
                showSingleYear={selectedYear}
                selectedMonth={selectedMonth}
              />
              <div>
                <small>
                  {textLang.statsData[lang]}
                  {lastUpdateTs}
                </small>
              </div>
              {walletData && walletData?.snapshots?.length > 0 && (
                <div>
                  <small>{textLang.statsDataAdd[lang]}</small>
                </div>
              )}
            </Modal.Body>
          )
        ) : (
          <Modal.Body
            as={Flex}
            className="justify-content-center align-items-center"
          >
            <h5>{textLang.noStats[lang]}</h5>
          </Modal.Body>
        )
      ) : (
        <Modal.Body
          as={Flex}
          className="justify-content-center align-items-center"
        >
          <Spinner />
        </Modal.Body>
      )}
    </Modal>
  );
};

MultiBenchmark.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  bot: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  eventPending: PropTypes.bool
};

export default MultiBenchmark;
